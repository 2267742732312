import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"

import {
  Hero,
  List,
  About,
  Convenience,
  Apartments,
  GallerySlider,
} from "page_components/home"

const Home = ({ data }) => {
  const allApartments = data.allWpApartment.nodes

  return (
    <Layout seo={{ title: "Strona główna" }} isHome>
      <Hero />
      <List apartmentList={allApartments} />
      <Apartments />
      <Convenience />
      <About />
      <GallerySlider />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpApartment(sort: { fields: title, order: ASC }) {
      nodes {
        acfApartment {
          area
        }
      }
    }
  }
`

export default Home
