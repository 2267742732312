import "./styles.scss"

import React, { useState, useEffect } from "react"
// import { Typewriter } from "react-simple-typewriter"

const Hero = () => {
  const [position, setPosition] = useState(0)
  // const [key, setKey] = useState(0)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setPosition(-window?.scrollY / 5)
    })
  }, [])

  return (
    <section className="home-hero">
      <div className="container-fluid">
        <div className="home-hero__wrapper">
          <h1 style={{ transform: `translateY(${position}px)` }}>
            Mieszkać
            <br /> blisko
          </h1>
        </div>
      </div>
    </section>
  )
}

export default Hero
