import "./styles.scss"

import React, { useState } from "react"
import { Link } from "gatsby"
import InputRange from "react-input-range"
import { Tooltip } from "react-tooltip"

import ZoomableMap from "components/ZoomableMap"

const List = ({ apartmentList }) => {
  const highestArea = Math.ceil(
      Math.max(...apartmentList.map(item => item.acfApartment.area))
    ),
    lowestArea = Math.floor(
      Math.min(...apartmentList.map(item => item.acfApartment.area))
    )

  const [areaRange, setAreaRange] = useState({
    value: { min: lowestArea, max: highestArea },
  })

  const [filters, setFilters] = useState({
    areaStart: lowestArea,
    areaEnd: highestArea,
    rooms: [],
    floors: [],
  })

  const setRange = value => {
    setAreaRange({ value })
    setFilters(prevFilters => ({
      ...prevFilters,
      areaStart: value.min,
      areaEnd: value.max,
    }))
  }

  const setRooms = roomNumber => {
    if (filters.rooms.includes(roomNumber)) {
      let rooms = filters.rooms
      const indexOfItem = filters.rooms.indexOf(roomNumber)

      rooms.splice(indexOfItem, 1)
      setFilters(prevFilters => ({ ...prevFilters, rooms: rooms }))
    } else {
      setFilters(prevFilters => ({
        ...prevFilters,
        rooms: [...prevFilters.rooms, roomNumber],
      }))
    }
  }

  const setFloor = floorNumber => {
    if (filters.floors.includes(floorNumber)) {
      let floors = filters.floors
      const indexOfItem = filters.floors.indexOf(floorNumber)

      floors.splice(indexOfItem, 1)
      setFilters(prevFilters => ({ ...prevFilters, floor: floors }))
    } else {
      setFilters(prevFilters => ({
        ...prevFilters,
        floors: [...prevFilters.floors, floorNumber],
      }))
    }
  }

  return (
    <section className="home-list">
      <div className="container-fluid">
        <h3>Lista mieszkań</h3>
        <div className="row row--filters">
          <div className="apartments-listing__filtering-range">
            <p>Metraż</p>
            <InputRange
              allowSameValues={false}
              maxValue={highestArea}
              minValue={lowestArea}
              value={areaRange.value}
              onChange={value => setRange(value)}
            />
            <div className="input-range__legend">
              <p>
                {areaRange.value.min}m<sup>2</sup>
              </p>
              <p>
                {areaRange.value.max}m<sup>2</sup>
              </p>
            </div>
          </div>
          <div>
            <p>Ilość pokoi</p>
            <div className="apartments-listing__filtering-row">
              <button
                onClick={() => setRooms(1)}
                className={filters.rooms.includes(1) ? "current" : ""}
              >
                1
              </button>
              <button
                onClick={() => setRooms(2)}
                className={filters.rooms.includes(2) ? "current" : ""}
              >
                2
              </button>
              <button
                onClick={() => setRooms(3)}
                className={filters.rooms.includes(3) ? "current" : ""}
              >
                3
              </button>
              <button
                onClick={() => setRooms(4)}
                className={filters.rooms.includes(4) ? "current" : ""}
              >
                4
              </button>
            </div>
          </div>
          <div>
            <p>Piętro</p>
            <div className="apartments-listing__filtering-row">
              <button
                className={`ground ${
                  filters.floors.includes(-1) ? "current" : ""
                }`}
                data-tooltip-id="tooltip-0"
                onClick={() => setFloor(-1)}
              >
                PN
                <Tooltip id="tooltip-0" place="top" content="Parter niski" />
              </button>
              <button
                className={`ground ${
                  filters.floors.includes(0) ? "current" : ""
                }`}
                data-tooltip-id="tooltip-1"
                onClick={() => setFloor(0)}
              >
                PW
                <Tooltip id="tooltip-1" place="top" content="Parter wysoki" />
              </button>
              <button
                onClick={() => setFloor(1)}
                className={filters.floors.includes(1) ? "current" : ""}
              >
                1
              </button>
              <button
                onClick={() => setFloor(2)}
                className={filters.floors.includes(2) ? "current" : ""}
              >
                2
              </button>
              <button
                onClick={() => setFloor(3)}
                className={filters.floors.includes(3) ? "current" : ""}
              >
                3
              </button>
              <button
                onClick={() => setFloor(4)}
                className={filters.floors.includes(4) ? "current" : ""}
              >
                4
              </button>
            </div>
          </div>
          <div>
            <Link
              className="home-list__cta"
              to="/mieszkania/"
              state={{ apartments: filters }}
            >
              Pokaż mieszkanie
            </Link>
          </div>
        </div>
      </div>
      <ZoomableMap />
    </section>
  )
}

export default List
